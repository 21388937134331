/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.panel-categories h4 {
  color: #004987;
  font-size: 1.375rem;
  line-height: 1.625rem;
  text-transform: none;
  letter-spacing: 0; }

.panel-categories .editorialRichText-component-container p {
  margin-bottom: 1.75rem; }

.panel-categories img {
  border-radius: 20px;
  height: auto; }

.panel-categories .rounded-cat-tile {
  position: relative;
  display: block; }
  .panel-categories .rounded-cat-tile:hover .icon {
    background: #004987; }
  .panel-categories .rounded-cat-tile:hover .fa-arrow-right::before {
    color: #fff; }

.panel-categories .cat-link {
  position: absolute;
  top: 15px;
  left: 15px;
  font-family: "BrandonGrotesque-WebMedium"; }

.panel-categories .icon {
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: 14px;
  left: 14px;
  border-radius: 20px;
  background: #fff;
  text-align: center;
  line-height: 22px; }

.cetaphil_redesign .panel-categories {
  margin: 25px; }
  @media (max-width: 1199.98px) {
    .cetaphil_redesign .panel-categories {
      margin: auto 11px;
      -ms-flex-wrap: inherit;
          flex-wrap: inherit;
      overflow: scroll;
      -ms-flex-pack: start;
          justify-content: flex-start; } }
  .cetaphil_redesign .panel-categories img {
    border-radius: 8px;
    min-height: 175px; }
    @media (max-width: 1023.98px) {
      .cetaphil_redesign .panel-categories img {
        min-height: 128px;
        min-width: 128px; } }
  .cetaphil_redesign .panel-categories ::-webkit-scrollbar {
    display: none; }
  .cetaphil_redesign .panel-categories .cat-link {
    font-family: "Lateral-CondensedBold"; }

.cetaphil_redesign ::-webkit-scrollbar {
  display: none; }
